<template>
    <el-form-item :label="$t('calendar.employees')">
        <el-select
            v-model="selectedEmployees"
            :placeholder="$t('calendar.employees')"
            multiple
            filterable
            class="w-full mb-2"
            @change="selectChanged"
        >
            <el-option
                v-for="employee in employeesData"
                :key="employee.uuid"
                :label="employee.name + ' ' + employee.surname"
                :value="employee.uuid"
            />
        </el-select>
    </el-form-item>
</template>
<script>
import includes from 'lodash/includes';

export default {
    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedEmployees: [],
            employeesData:     [],
        };
    },

    watch: {
        modalVisible() {
            this.selectedEmployees = this.$store.state.calendar.preSelectedEmployee;
            this.selectChanged();
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            this.employeesData = data;
            this.selectedEmployees = [];
            // this.selectedEmployees = this.$store.state.calendar.preSelectedEmployee;
            this.selectChanged();
        },

        selectChanged() {
            const employees = this.employeesData.filter(employee => includes(this.selectedEmployees, employee.uuid));

            this.$emit('change', employees);
        },
    },
};
</script>
